"use client";

import Drawer from "@/components/drawer";
import { Icons } from "@/components/icons";
import Menu from "@/components/menu";
import { buttonVariants } from "@/components/ui/button";
import { siteConfig } from "@/lib/config";
import { cn } from "@/lib/utils";
import Link from "next/link";
import { useEffect, useState } from "react";
import NewsletterSubscribe from "../../newsletter-subscribe/newsletter-subscribe";

export default function Header() {
  const [addBorder, setAddBorder] = useState(false);
  const [openNewsletterModal, setOpenNewsletterModal] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 20) {
        setAddBorder(true);
      } else {
        setAddBorder(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Add timer to open newsletter after xx seconds
  useEffect(() => {
    const timer = setTimeout(() => {
      setOpenNewsletterModal(true);
    }, 20000); // 20 seconds delay
    return () => clearTimeout(timer); // Cleanup timer on unmount
  }, []);

  const openModal = () => {
    setOpenNewsletterModal(true);
  }
  const closeModal = () => {
    setOpenNewsletterModal(false);
  }

  const getHeader=()=>{
    try{
return <header
className={
  "relative sticky top-0 z-50 py-2 bg-background/60 backdrop-blur"
}
>
<div className="flex justify-between items-center container">
  <Link
    href="/"
    title="brand-logo"
    className="relative mr-6 flex items-center space-x-2"
  >
    <Icons.logo/>
    {/* <span className="font-bold text-xl">{siteConfig.name}</span> */}
  </Link>

  <div className="hidden lg:block">
    <div className="flex items-center ">
      <nav className="mr-10">
        <Menu />
      </nav>

      <div className="gap-2 flex">
              <div onClick={openModal}>
                <Link href="#" className={buttonVariants({ variant: "ghost" })}>
                  Newsletter
                </Link>
              </div>
        <a 
          className={buttonVariants({ variant: "ghost" })} 
          href="https://outlook.office365.com/owa/calendar/FugroGeodataIntelligencePlatform@fugro.onmicrosoft.com/bookings/" 
          target="_blank">
            Get a demo
        </a>
        <Link
          href="/api/auth/login"
          className={buttonVariants({ variant: "outline" })}
        >
          Login
        </Link>
        <Link
          href="/api/auth/login"
          className={cn(
            buttonVariants({ variant: "default" }),
            "w-full sm:w-auto flex gap-2"
          )}
        >
          <Icons.getStarted className="w-auto h-[30px]" />
          Get started for free
        </Link>
      </div>
    </div>
  </div>
  <div className="mt-2 cursor-pointer block lg:hidden">
    <Drawer />
  </div>
</div>
<hr
  className={cn(
    "absolute w-full bottom-0 transition-opacity duration-300 ease-in-out",
    addBorder ? "opacity-100" : "opacity-0"
  )}
/>
      {(openNewsletterModal)?
        <NewsletterSubscribe onClose={closeModal} />
        :<></>
      }

</header>
    }
    catch(e){
      return <>Hello</>
    }
  }

  return (
    <>
    {getHeader()}
    </>
  );
}
