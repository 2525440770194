import React, { useState } from 'react';
import { Box, TextField, Typography, IconButton, Modal } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CrmService from '@/app/services/api/backend/crm/crmService';
import Logger from '@/app/utils/logger';
import newsLetterImage from '@/assets/landingpage/newsletter.jpeg';
import Image from 'next/image';

interface NewsletterSubscribeProps {
  onClose: () => void;
}

const NewsletterSubscribe: React.FC<NewsletterSubscribeProps> = ({ onClose }) => {
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [finalMessage, setFinalMessage] = useState<string>('');

  const crmService: CrmService = new CrmService();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      const encodedEmail = encodeURIComponent(email);
      const response = await crmService.getContactByEmail(encodedEmail);
      if (response && response.data.length > 0) {
        setFinalMessage(
          "You're already part of the community! Thank you for staying connected."
        );
      } else {
        await crmService.createContact(firstName, lastName, email);
        setFinalMessage(
          "Welcome to Fugro Geo-Data Intelligence! You'll start receiving updates soon."
        );
      }
    } catch (error: any) {
      Logger.error(`Error subscribing to HubSpot: ${error?.message}`);
      setFinalMessage(
        'Oops! Something went wrong. Please try subscribing again later or contact support.'
      );
    }
  };

  return (
    <Modal open={true} onClose={onClose}>
      <Box
        component="div"
        sx={{
          display: 'flex',
          flexDirection: 'row',
          maxHeight: '70vh',
          maxWidth: '900px',
          width: '80vw',
          bgcolor: '#FFC800',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          boxShadow: 24,
          borderRadius: 2,
          overflow: 'hidden',
          '@media (max-width: 600px)': {
            flexDirection: 'column',
            width: '90vw',
          },
        }}
      >
        {/* Left Image Section */}
        <Box
          component="div"
          sx={{
            flex: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'relative',
            overflow: 'hidden',
          }}
        >
          <Image
            src={newsLetterImage}
            alt="Newsletter Illustration"
            layout="fill" // Ensures the image fills the container
            objectFit="cover" // Scales the image proportionally
            priority
          />
        </Box>

        {/* Right Content Section */}
        <Box
          component="div"
          sx={{
            flex: 1.2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            p: 3,
          }}
        >
          {/* Close Button */}
          <IconButton
            aria-label="close"
            sx={{
              position: 'absolute',
              top: 12,
              right: 12,
              zIndex: 10,
              color: 'black',
            }}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>

          {/* Title */}
          <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 2 }}>
            Join The Community!
          </Typography>

          {finalMessage.length === 0 ? (
            <>
              <Typography
                variant="body2"
                align="center"
                sx={{ mb: 4, fontSize: '0.9rem', lineHeight: 1.5 }}
              >
                Stay ahead in Geo-Data Intelligence. Receive the latest news,
                industry insights, and updates delivered straight to your inbox. You
                can unsubscribe at any time.
              </Typography>

              <form onSubmit={handleSubmit} style={{ width: '100%' }}>
                <Box 
                  component="div"
                  sx={{ display: 'flex', gap: 2, mb: 2 }}
                  >
                  <TextField
                    fullWidth
                    label="First Name"
                    type="text"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    variant="filled"
                    sx={{ bgcolor: '#fff' }}
                    required
                  />
                  <TextField
                    fullWidth
                    label="Last Name"
                    type="text"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    variant="filled"
                    sx={{ bgcolor: '#fff' }}
                    required
                  />
                </Box>
                <TextField
                  fullWidth
                  label="Email address"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  variant="filled"
                  sx={{ mb: 3, bgcolor: '#fff' }}
                  required
                />
                <button
                  type="submit"
                  className="w-full bg-black text-white py-2 text-md font-bold rounded hover:bg-gray-800 transition-all"
                >
                  Sign Up
                </button>
                <Typography
                  variant="caption"
                  align="center"
                  sx={{ mt: 2, fontSize: '0.8rem' }}
                >
                  By signing up, you consent to receive Geo-data Intelligence news and
                  updates. Read our{' '}
                  <a
                    href="https://www.fugro.com/privacy-notice"
                    target="_blank"
                    style={{ textDecoration: 'underline', color: 'blue' }}
                  >
                    Privacy Policy
                  </a>{' '}
                  to learn how we protect and manage your data.
                </Typography>
              </form>
            </>
          ) : (
            <Typography variant="body2" align="center" sx={{ fontWeight: 'bold' }}>
              {finalMessage}
            </Typography>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export default NewsletterSubscribe;
